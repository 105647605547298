import { Grow, Paper, Button } from '@material-ui/core';
import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CodigoReservaCard from '../../components/codigo-reserva-card/codigo-reserva-card.component';
import impresion from '../../components/impresion/impresion.function';
import { Devolucion } from '../../models/devolucion.model';
import { IReserva, IUbicacionEventoes } from '../../models/reserva.model';
import { URI_LOGGED_USER } from '../../shared/api-uris/uri-auth';
import { URI_DEVOLUCIONES } from '../../shared/api-uris/uri-devoluciones';
import { uriReservas } from '../../shared/api-uris/uri-reservas';
import { getTokenForUrl, handleFetchError, handleFetchSuccess } from '../../shared/utils/utils';
import passwordDialog from './components/password-dialog/password-dialog.component';
import Tabla from './components/tabla/tabla.component';
import UbicacionesList from './components/ubicaciones-list/ubicaciones-list.component';
import homeStyles from './home.styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import homeReducer from './reducers/home.reducer';
import { URI_MS_PDF } from '../../shared/api-uris/uri-mspdf';

const useStyles = homeStyles;

const transformarTimeToDate = (time: number | null) => {
  if (!time) return '**vacio**';
  const day = new Date(time).getDate();
  const month = new Date(time).getMonth();
  const year = new Date(time).getFullYear();
  return `${day}/${month + 1}/${year}`;
};

const listarAnulaciones = (anulaciones: IUbicacionEventoes[])=>`
      <p>Evento: ${anulaciones[0].sectorEventoId.eventoId.nombre + ' (' + anulaciones[0].sectorEventoId.eventoId.id})</p>
      <p>${anulaciones[0].sectorEventoId.descripcion} - Fecha: ${transformarTimeToDate(anulaciones[0].sectorEventoId.fechaFuncion)}</p>

      <ul>${anulaciones.map((anulacion, idx)=>
  `<li>Ubicacion: ${anulacion.id} - Descuento: ${anulaciones[0].descuentoSectorId.descripcion}</li>`)} 
      </ul>`;

const confirmAnularDialog = async (
  message: string,
  anulaciones: IUbicacionEventoes[],
  clean?: boolean
) => {
  return Swal.fire({
    icon: 'question',
    titleText: message,
    html: listarAnulaciones(anulaciones),
    confirmButtonText: 'ANULAR',
    confirmButtonColor: 'red',
    showCancelButton: true,
    cancelButtonText: 'Cancelar'
  });
};

const initialState = {
  open: false,
  reserva: null,
  disabledConfirm: false,
  ubicaciones: [],
  codigo: '',
  turnoParams: '',
  showError: undefined,

};

const Home = () => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(homeReducer, initialState);
  const { codigo, disabledConfirm, reserva, ubicaciones, open, turnoParams } = state;
  const { turnoId } = useParams() as { turnoId: string | number };

  useEffect(() => {
    dispatch({ type: 'setTurnoParam', payload: turnoId });
  }, [turnoId]);

  const searchReservaAndSetData = async (codigo: string) => {
    dispatch({ type: 'setCodigo', payload: codigo });
    try {
      let response = await fetch(uriReservas(codigo) + getTokenForUrl(), { method: 'GET' });

      if (!response.ok) {
        dispatch({ type: 'error', payload: response.statusText });
        if (response.status === 401) {
          localStorage.clear();
          window.location.replace('/');
        }
        return;
      }

      const data = await response.json();

      if (data.turnoId === null) {
        Swal.fire({
          title: 'Esta reserva no tiene Turno Id',
          html: 'Ingrese Turno Id:',
          icon: 'warning',
          input: 'text',
          showCancelButton: false,
          inputValidator: (turnoId: any) => {
            if(turnoId == "") return 'Debe ingresar id' ;
            return null;
          },
          inputAttributes: {
            maxlength: '150'
          }
        }).then(result => {
          if(result.value)
            dispatch({ type: 'setTurnoParam', payload: result.value });
          else {
            window.location.reload()
          }
        });

      }
      if (checkEstadoDistintoE(data.estado)) return;
      dispatch({ type: 'loaded', payload: data });

    } catch (error) {
      handleFetchError(Swal, error).then(() => window.location.reload());
    }
  };

  const checkEstadoDistintoE = (estado: string) => {
    if (estado !== 'E') {
      let error = 'La reserva tiene un estado distinto de E o ya esta anulada';
      dispatch({ type: 'error', payload: error });
      return true;
    }
  };

  const handleAnulacion = async (
    anulaciones: IUbicacionEventoes[],
    comentario: string,
    clean?: boolean
  ) => {
    const swalAnulacionMessage = clean
      ? '¿Seguro que quiere anular todas las reservas?'
      : 'Seguro que quiere anular las siguientes reservas';
    const { value: confirm } = await confirmAnularDialog(swalAnulacionMessage, anulaciones, clean);
    const { value: password } = await passwordDialog();

    if (!password || !confirm) return;

    try {
      let paraAnular = anulaciones;
      if (clean) paraAnular = [];
      const responseUser = await getLoggedUser();
      const dataUser = await responseUser.json();
      const responseAnular = await postReserva(reserva, paraAnular, comentario, dataUser);
      if (responseAnular && !responseAnular.ok) {
        dispatch({ type: 'error', payload: `Error al anular: ${responseAnular.statusText}` });
        return;
      }

      if (clean) {
        dispatch({ type: 'limpiar' });
      } else {
        dispatch({ type: 'anularParcial', payload: anulaciones });
      }
      imprimir(anulaciones, reserva, comentario);
      handleFetchSuccess('Reservas anuladas', Swal);
    } catch (error) {
      handleFetchError(Swal, error);
    }


  };

  const postReserva = (
    reserva: IReserva,
    anulaciones: IUbicacionEventoes[],
    comentario: string,
    dataUser: any
  ) => {
    const detalleDevolucion = anulaciones.map(anulacion => ({
      ubicacionEventoId: anulacion.id
    }));
    const importe = getImporte(anulaciones) || reserva.importeTotal;
    const turnoId = getTurnoId(reserva, turnoParams);
    if (!turnoId) return;
    const devolucion = new Devolucion(
      comentario,
      new Date().getTime(),
      importe,
      detalleDevolucion,
      reserva.id,
      turnoId,
      reserva.vendedorId.id,
      dataUser.id
    );

    return fetch(URI_DEVOLUCIONES + getTokenForUrl(), {
      method: 'POST',
      body: JSON.stringify(devolucion)
    });
  };

  const getLoggedUser = () => {
    return fetch(URI_LOGGED_USER + getTokenForUrl(), {
      method: 'GET'
    });
  }

  const getTurnoId = (reserva: IReserva, turnoParam: string | undefined): number | undefined => {
    if (turnoParam) return +turnoParam;
    if (reserva.turnoId && reserva.turnoId.id) return reserva.turnoId.id;

    dispatch({
      type: 'error',
      payload: 'CANCELADO, No se encuentra un turno en la reserva ni en la ruta'
    });
    return undefined;
  };

  const getImporte = (anulaciones: IUbicacionEventoes[]) => {
    if (anulaciones.length === 0) return null;
    return anulaciones.reduce((accum, anulacion) => {
      accum = accum + anulacion.precio;
      return accum;
    }, 0);
  };

  const closeUbicacionesList = () => {
    dispatch({ type: 'limpiar' });
  };

  const generatePdf = async () => {

    const token = localStorage.getItem('token');
    const ubicacionEntradas = reserva.ubicacionEventoes.map((ubicacion : any) => {
      return {
        id: ubicacion.id,
        etiqueta: ubicacion.ubicacionId.etiqueta,
        descuento: ubicacion.descuentoSectorId.descripcion,
        ingreso: ubicacion.ingreso,
        fechaIngreso: ubicacion.fechaIngreso
      };
    });

    const reservaData = {
      reservaId: reserva.id,
      clienteDni: reserva.clienteId.dni,
      clienteNombre: reserva.clienteId.nombre,
      fechaFacturacion: reserva.fechaFacturacion,
      tipo: reserva.tipo,
      estado: reserva.estado,
      fechaIngreso: reserva.ubicacionEventoes[0]?.fechaIngreso,
      importeTotal: reserva.importeTotal,
      evento: reserva.ubicacionEventoes[0].sectorEventoId.eventoId.nombre,
      escenario: reserva.ubicacionEventoes[0].sectorEventoId.eventoId.escenarioId.nombre,
      vendedorDni: reserva.vendedorId.dni,
      vendedorNombre: reserva.vendedorId.nombre,
      sectorNombre: reserva.ubicacionEventoes[0].sectorEventoId.sectorId.nombre,
      turnoId: reserva.turnoId?.id,
      sucursalNombre: reserva.turnoId?.cajaId?.puntoVentaId?.sucursalId?.nombre,
      funcion: reserva.ubicacionEventoes[0].descuentoSectorId.sectorEventoId.descripcion,
      totalEntradas: ubicacionEntradas.length,
      ubicacionEntradas
    };

    try {

      const response = await fetch(URI_MS_PDF, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(reservaData)
      });

      if (!response.ok) {
        throw new Error('Error al obtener el PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'reserva.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      Swal.fire({
        title: "PDF generado con éxito",
        icon: "success"
      });

    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        icon: "error",
        title: "¡Error al generar PDF!",
      });
    }
  }

  const imprimir = (anulaciones: IUbicacionEventoes[], datos: IReserva, comentario: string) => {
    const dia = new Date().getDate();
    const mes = new Date().getMonth() + 1;
    const año = new Date().getFullYear();
    const titulo = `Anulación ${dia}/${mes}/${año}`;
    const turnoIDOriginal = datos.turnoId ? datos.turnoId.id : 'Null';
    const turnoIDAnulador = turnoParams ? turnoParams : turnoIDOriginal;
    const content = anulaciones.map(anulacion => `*ID ubicación: ${anulacion.id}`);

    content.push(`<span style="position: absolute; bottom:0">
      ***Datos***
    <br>TurnoId original: ${turnoIDOriginal}
    <br>Evento: ${datos.ubicacionEventoes[0].sectorEventoId.eventoId.nombre}
    <br>Sector: ${datos.ubicacionEventoes[0].sectorEventoId.sectorId.nombre}
    <br>TurnoId que anula: ${turnoIDAnulador}
    <br>Comentario: ${comentario}
    <br>Codigo verificación: ${datos.id}
    <br>DNI cliente: ${datos.clienteId.dni}
    <br>Nombre Cliente: ${datos.clienteId.nombre}</span>
    `);
    impresion({ content, title: titulo });
  };

  return (
    <>
      <div className={classes.titleHome}>
        <h2>Devoluciones</h2>
        <div className={classes.lineTitle} />
      </div>

      <section className={classes.homePage}>
        <CodigoReservaCard
          placeHolder="Codigo"
          title="Ingrese codigo de reserva"
          handleChange={searchReservaAndSetData}
          disabledButton={disabledConfirm}
        />

        <div className={classes.etiquetasContainer}>
          {ubicaciones && (
            <Grow in={open} mountOnEnter>
              <Paper elevation={4}>
                <UbicacionesList
                  ubicaciones={ubicaciones!}
                  onAnulacion={handleAnulacion}
                  onCancel={closeUbicacionesList}
                  reservaId={codigo}
                />
              </Paper>
            </Grow>
          )}
        </div>
      </section>
      {ubicaciones.length > 0 && (
        <>
          <div className={classes.titleHome}>
            <h3>
              Datos de la reserva :<span style={{ color: '#e16162' }}> {codigo}</span>
            </h3>
            <div className={classes.lineTitle} />
            <Button
              variant="contained"
              color="primary"
              style={{ marginBottom: '16px' }}
              startIcon={<PictureAsPdfIcon />}
              onClick={generatePdf}
            >
              PDF
            </Button>
          </div>
          <Grow in={open} mountOnEnter unmountOnExit>
            <Tabla row={reserva!} />
          </Grow>
        </>
      )}
    </>
  );
};

export default Home;
