import { Button, Card, CardActions, ListSubheader } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { IUbicacionEventoes } from '../../../../models/reserva.model';
import ubicacionesListStyles from './ubicaciones-list.styles';

const useStyles = ubicacionesListStyles;

interface IUbicacionesList {
  ubicaciones: (IUbicacionEventoes & { isChecked?: boolean | undefined })[];
  onAnulacion: (anulaciones: IUbicacionEventoes[], comentario: string, clean?: boolean) => void;
  onCancel: () => void;
  reservaId: string;
}

const UbicacionesList = ({ ubicaciones, onAnulacion, onCancel, reservaId }: IUbicacionesList) => {

  const classes = useStyles();
  const [_ubicaciones, setUbicaciones] = useState(ubicaciones);
  const [comentario, setComentario] = useState('');
  const [allChecked, setallChecked] = useState(false);

  useEffect(() => {
    setUbicaciones(ubicaciones);
  }, [ubicaciones]);

  const handleToggle = (value: IUbicacionEventoes) => () => {
    const currentIndex = _ubicaciones.indexOf(value);
  

    if (currentIndex !== -1) {
      setUbicaciones(state => {
        const newState = [...state];
        newState[currentIndex].isChecked = !newState[currentIndex].isChecked;
        let contador = 0;
        newState.forEach(e => {
          if (e.isChecked) contador++;
        });
        if (contador === _ubicaciones.length) {
          setallChecked(true);
        } else {
          setallChecked(false);
        }
        return newState;
      });
    }
  };
  
  const swalComentario = () => {
    return Swal.fire({
      title: 'Comentario de la anulacion',
      input: 'text',
      inputValue: comentario,
      showCancelButton: true,
      inputValidator: (value: any) => {
        if (!value) return 'Es necesario ingresar un valor';
        return null;
      },
      inputAttributes: {
        maxlength: '150'
      }
    });
  };

  const swalConfirmacionEventoId = () => {
    return Swal.fire({
      title: 'Confirmar evento id',
      input: 'text',
      inputValue: comentario,
      showCancelButton: true,
      inputValidator: (eventoId: any) => {
        
        if (!eventoId) return 'Es necesario ingresar un valor';
        if (eventoId != (_ubicaciones[0].sectorEventoId.eventoId.id)) return 'Ingrese ID correcto';
        return null;
        
      },
      inputAttributes: {
        maxlength: '150'
      }
    });
  };
  
  const buttonAnulacion = async () => {
    const { value } = await swalComentario();
    const eventoId = (await swalConfirmacionEventoId()).value;
    if (value && eventoId  === (_ubicaciones[0].sectorEventoId.eventoId.id).toString()) {
      setComentario(value);
      swalConfirmacionEventoId()
      const checked = findChecks();
      onAnulacion(checked, value, checked.length === ubicaciones.length);
    }
  };

  const findChecks = () => {
    return _ubicaciones.filter(element => element.isChecked);
  };

  const selectAll = () => {
    setallChecked(prev => {
      setUbicaciones(state =>
        state.map(s => {
          s.isChecked = !prev;
          return s;
        })
      );
      return !prev;
    });
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('es-AR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  return (
    <Card>
      <List
        subheader={
          <div className={classes.selectAllList}>
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': '' }}
              checked={allChecked}
              onChange={selectAll}
            />
            <ListSubheader>/Seleccionar todos</ListSubheader>
            <h3>
              {' '}
              &nbsp; Reserva id : <span style={{ color: '#e16162' }}>{reservaId}</span>
              &nbsp; Total de Entradas : <span style={{ color: '#225cb3' }}>{_ubicaciones.length}</span>
            </h3>
          </div>
        }
        className={classes.root}
      >
        {_ubicaciones.map(value => {
          console.log('Total de Entradas:' + _ubicaciones.length);
          const labelId = `checkbox-list-label-${value}`;
          return (
            <>
            <ListItem key={value.id} role={undefined} dense onClick={handleToggle(value)}>

              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  checked={value.isChecked ? true : false}
                />
              </ListItemIcon>
              <p>
                <span className={classes.ubicacionListId}>Id Ubicacion: </span>
                {value.id}         
                <span className={classes.ubicacionesListEtiquetas}> Etiqueta: </span>
                {value.ubicacionId.etiqueta}
                <span className={classes.ubicacionesListDescuento}> Descuento: </span>
                {value.descuentoSectorId.descripcion}
                <span className={classes.ubicacionesListIngreso}> Ingreso: </span>
                {value.ingreso}
                {value.ingreso === 'E' && (
                <>
                <span className={classes.fechaIngreso}> Hora ingreso: </span>
                  {formatDate(value.fechaIngreso)}
                </>
                )}
              </p>
            
              <ListItemSecondaryAction />

            </ListItem>
              <div style={{height: '0.8px', border: '1px solid #7D7972', width: '95%', marginLeft: '18px'}}></div>
            </>
          );
        })}
      </List>
      <CardActions>
        <Button
          variant="contained"
          style={{ backgroundColor: '#e16162' }}
          onClick={buttonAnulacion}
          disabled={findChecks().length === 0}
        >
          ANULAR
        </Button>
        <Button variant="contained" color="default" onClick={onCancel}>
          Cancelar
        </Button>
      </CardActions>
    </Card>
  );
};


export default UbicacionesList;
